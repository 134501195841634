import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  CircularProgress,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import StudyService from '../../../Services/StudyService/StudyService';
import { TramRounded } from '@material-ui/icons';
import { StudiesContext } from '../../../contexts/Studies';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  nctidTitle: {
    fontSize: 12,
    fontFamily: theme.typography.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  studyTitle: {
    fontSize: 12,
    fontFamily: theme.typography.main,
    marginTop: 12,
    color: '#4A4A4A',
    fontWeight: 'bold',
  },
  companyTitle: {
    fontSize: 12,
    fontFamily: theme.typography.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginTop: 12,
    letterSpacing: 2,
  },
  AddNewStudyCenterLabel: {
    fontSize: 12,
    marginTop: 24,
    fontFamily: theme.typography.main,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    letterSpacing: 2,
  },
  StudyCenterNameTeamPopup: {
    fontSize: 24,
    marginTop: 24,
    fontFamily: theme.typography.main,
    fontWeight: 'bold',
  },
  addNewStudyCenter: {
    'fontSize': 14,
    'backgroundColor': theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      color: 'white',
    },
    'borderRadius': 10,
    'fontWeight': 'bold',
    'textAlign': 'center',
    'fontSize': 16,
    'color': 'white',
    'fontWeight': 'bold',
    'textTransform': 'none',
    'position': 'absolute',
    'bottom': 16,
    'width': 'calc(100% - 48px)',
    'height': 44,
    'marginTop': 24,
    'letterSpacing': 2,
  },
}));

export default function AlertDialogSlide(props) {
  const classes = useStyles();

  const { getStudies } = useContext(StudiesContext);

  const [team, setTeam] = useState([]);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [addNew, setAddNew] = React.useState(false);
  const [location, setLocation] = useState(null);
  const [name, setName] = useState(null);
  const [canAdd, setCanAdd] = useState(false);
  const [adding, setAdding] = useState(false);
  const [NCTID, setNCTID] = useState('');
  const [studiesFound, setStudiesFound] = useState([]);

  const handleClose = () => {
    setNCTID('');
    setStudiesFound([]);
    props.setOpenAddNewStudy(false);
  };

  useEffect(() => {
    if (studiesFound.length > 0) {
      setCanAdd(true);
    } else {
      setCanAdd(false);
    }
  }, [studiesFound]);

  useEffect(() => {
    if (NCTID.length == 11) {
      getStudiesByNCTID();
    } else {
      setStudiesFound([]);
    }
  }, [NCTID]);

  const getStudiesByNCTID = async () => {
    // let results = await StudyService.getStudiesByNCTID(NCTID);
    const url = `https://us-central1-knowrare-platform-e2cc9.cloudfunctions.net/fetchStudies`;
    const res = await axios.post(
      url,
      { dName: NCTID },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      },
    );
    // var study = res.data[0];
    // var locations = study.LocationFacility;
    // for (let index = 0; index < locations.length; index++) {
    //   const location = locations[index];
    //   if (location.toLowerCase().replace(/ /g, '-') === studyLocation) {
    //   }
    // }
    console.log('res', res.data);
    setStudiesFound(res.data);
  };
  // NCT05799755
  const addNewStudy = async () => {
    if (adding) return;
    let newStudyData = {
      customTitle: studiesFound[0].BriefTitle[0],
      isDiagnosisRequired: false,
      isSponsored: false,
      status: 'Paused',
    };
    let companyName = studiesFound[0].LeadSponsorName[0];
    setAdding(true);
    await StudyService.addNewStudy(companyName, NCTID, newStudyData);
    await getStudies();
    setAdding(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        fullWidth={true}
        maxWidth={'sm'}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-described
        by="alert-dialog-slide-description"
        style={{ minWidth: 490, minHeight: 450 }}
      >
        <DialogContent style={{ position: 'relative' }}>
          <IconButton
            style={{ position: 'absolute', right: 16, top: 16 }}
            onClick={handleClose}
          >
            <img
              alt=""
              src={require('../../../Assets/Images/grayXIcon.png')}
              width={14}
              height={14}
            />
          </IconButton>
          <div style={{ minHeight: 250, paddingBottom: 100 }}>
            <Typography className={classes.StudyCenterNameTeamPopup}>
              Add New
            </Typography>
            {/* <Typography className={classes.StudyCenterLocationTeamPopup}>{props.data.data().LocationAddress}</Typography> */}
            <Typography className={classes.AddNewStudyCenterLabel}>
              Study NCTID
            </Typography>
            <TextField
              fullWidth
              style={{ marginTop: 12 }}
              onChange={(e) => setNCTID(e.target.value)}
            />
            {studiesFound.length > 0 && (
              <div
                style={{
                  width: '100%',
                  borderRadius: 10,
                  backgroundColor: 'white',
                  padding: 12,
                  marginTop: 24,
                  boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.12)',
                }}
              >
                <Typography className={classes.nctidTitle}>{NCTID}</Typography>
                <Typography className={classes.studyTitle}>
                  {studiesFound[0].BriefTitle[0]}
                </Typography>
                <Typography className={classes.companyTitle}>
                  {studiesFound[0].LeadSponsorName[0]}
                </Typography>
              </div>
            )}
          </div>
          {canAdd ? (
            <Button
              className={classes.addNewStudyCenter}
              onClick={() => addNewStudy()}
            >
              {adding ? (
                <CircularProgress size={24} style={{ color: 'white' }} />
              ) : (
                'Add Study'
              )}
            </Button>
          ) : (
            <Button
              className={classes.addNewStudyCenter}
              disabled
              style={{ opacity: 0.5 }}
            >
              Add Study
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
